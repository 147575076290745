@import url('https://fonts.googleapis.com/css?family=Assistant:700&display=swap');

body {
  background-color: #f2f3f4;
  font-family: 'Assistant', sans-serif;
  margin: 0;
  position: relative;
}

section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

section h1 {
  font-size: 36px;
}

.logo {
  position: absolute;
  top: 0px;
  left: 15px;
  z-index: 100;
  display: flex;
  width: 350px;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.logo .chaboncin {
  height: 125px;
  fill: none;
  stroke: #fff;
  margin-right: 25px;
  width: 125px;
}

.logo .chaboncin .st1 {
  fill: #fff;
}

.logo .iso {
  background-image: url('../static/logos/frilogo.svg');
  background: none;
  fill: #fff;
  height: 125px;
  width: 250px;
}

.player-nav {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 55px;
}
.player-nav h1 {
  font-family: 'assistant', sans-serif;
  margin: 0;
  padding: 0 15px;
}

.image-wrap {
  height: calc(100vh - 55px);
  width: 100vw;
}

.image-wrap img {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  html,
  body {
    display: flex;
    min-height: 100%;
    flex-direction: column;
  }

  body {
    flex: auto;
  }

  .image-wrap {
    display: none;
  }

  .logo {
    flex-direction: column;
    flex: 1 0 auto;
    left: 0;
    position: relative;
    width: auto;
  }

  .logo .chaboncin {
    margin: 0;
  }

  .logo svg.chaboncin {
    stroke: #000;
    stroke-width: 2px;
    fill: none;
  }
  .logo svg.chaboncin path {
    stroke: #000;
  }

  .logo svg.iso {
    fill: #000;
  }

  .player-nav {
    flex: 0 0 55px;
  }
}
